* {
    margin: 0;
    padding: 0;
}

body {
    padding: 2rem;
    font-family: Arial, Helvetica, sans-serif;
    font-family: "Lucida Console", "Courier New", "Mono";
    background-color:  rgb(80, 80, 80);
    color: white;
}

.baseInformationBox {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;

    > div:first-child {
        margin-right: 3rem;
    }

    > div.companyName {
        font-weight: bold;
        > span:first-child {
            font-size: 4rem;
            display: inherit;
            flex-grow: 0;
            align-items: center;
        }

        > span:last-child {
            position: relative;
            top: -0.5rem;
        }
    }

    > div.socialMedia {
        display: inherit;
        align-items: center;
        flex-grow: 1;
        color: white;
        a {
            text-decoration: none;
            color: inherit;
        }
        
        > span {
            border: 1px solid white;
            padding: 0.5rem;
        }
        > span:hover {
            box-shadow: -0.1rem -0.1rem 0.5rem;
        }
        span:hover {
            cursor: pointer;
            color: white;
            
        }


    }
}

.baseUnderConstruction {
    margin-top: 1rem;
}

.contactContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
}

.contactCard {
    display: flex;
    box-shadow: -0.1rem -0.1rem 0.5rem;
    border-radius: 0.25rem;
    padding: 0.5rem;
    &:hover {
        color: green;
        background-color: black;
        box-shadow: none;
    }
}

.contactCardPicture {
    height: 0.25vh;
}

.contactCardInformation {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
}